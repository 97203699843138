/* Main container styling */
.main-view1 {
 
/* Center and style the header */
.text-center {
    color: #333;
    text-transform: uppercase;
}

/* Responsive table styles */
table {
    background-color: #fff !important;
    border-collapse: collapse;
    width: 100%;
    --bs-table-bg:none !important;
}

table thead tr {
    font-weight: bold;
    text-transform: uppercase;
    text-align: left;
    border-bottom: 2px solid #ddd;
    padding: 10px 0;
}

table tr {
    border-bottom: 1px solid #dee2e6;
}

table tr.bg {
    font-weight: bold;
}

td {
    padding: 0 10px;
    border-bottom: 1px solid #ddd;
    text-align: left;
}
td.pri {
    padding: 0 10px;
    border-bottom: 1px solid #ddd;
    text-align: right;
}
th.pri{
    text-align: right;

}


.table-responsive {
    overflow-x: auto;
    white-space: nowrap;
}
/* Enable word wrapping in table cells */
.wrap-text {
    word-wrap: break-word; /* Allows breaking at word boundaries */
    word-break: break-word; /* Ensures words break if they're too long */
    white-space: normal; /* Allows multi-line content */
    text-align: left; /* Align text to the left for readability */
    padding: 10px; /* Optional: Add padding for spacing */
    max-width: 200px; /* Optional: Limit the width for better control */
    line-height: 1.5; /* Add line spacing for readability */
}


/* Adjust spacing in sections */
.container_pay {
    margin: 0 auto;
    width: 40%;
    padding: 20px;
    margin-bottom: 20px;
    background-color: #f4f4f4;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    
}
/* Adjust spacing in sections */
.container1 {
    
    padding: 15px;
    margin-bottom: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.container1 .btn {
    width: 100%; /* Makes the button occupy the full width of the container */
    max-width: 100%; /* Ensures it doesn't exceed the container's width */
    padding: 15px; /* Adds padding for a better look */
    text-transform: uppercase; /* Optional for styling */
    font-size: 18px; /* Makes the text legible */
    font-weight: bold;
    background-color: #080808;
    color: white;
    border: none;
    border-radius: 4px;
}

.container1 .btn:hover {
    background-color: #4f504e;
}
/* Button styling */
.btn-success {
    width: 100%;
    padding: 15px 0;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
    position: fixed;
    bottom: 0;
    left: 0;
    border-radius: 0;
    background-color: #28a745;
    border: none;
    color: #ffffff;
}

.btn-success:hover {
    background-color: #218838;
}

h3 {
    margin-top: 10px;
    font-weight: bold;
    color: #555;
}
.Order{
    font-weight: 700;
}

 /* Media query for small devices */
@media (max-width: 768px) {
    /* Adjust headers for smaller screens */
    h2, h3 {
        font-size: 16px;
        text-align: center;
    }

    /* Adjust button sizes */
    .btn-success {
        font-size: 14px;
        padding: 8px 0;
        bottom: 10px;
    }

    /* Make tables scrollable and compact */
    table td, table th {
        font-size: 12px;
        padding: 8px;
    }

    .table-responsive {
        overflow-x: auto; /* Allows horizontal scrolling */
    }

    /* Adjust container padding and layout */
    .container_pay, .container1 {
        width: 90%; 
        margin: 10px auto; 
        padding: 10px;
    }

    /* Ensure text wraps properly */
    .wrap-text {
        font-size: 14px;
        line-height: 1.4;
    }
}
@media (min-width: 768px) and (max-width: 992px) {
    /* Adjust headers for medium screens */
    h2, h3 {
        font-size: 18px;
    }

    /* Adjust button sizes */
    .btn-success {
        font-size: 16px;
        padding: 10px 0;
    }

    /* Make containers adapt */
    .container_pay, .container1 {
        width: 80%;
        margin: 20px auto;
        padding: 15px;
    }

    /* Adjust table text for readability */
    table td, table th {
        font-size: 14px;
    }
}

@media (min-width: 1300px) and (max-width:  1200px) {
    .container_pay {
        width: 100%;
        margin: 0 auto;
        padding: 15px;
        margin-bottom: 20px;
        background-color: #ffffff;
        border-radius: 8px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }
    /* Adjust spacing in sections */
    .container1 {
        
        padding: 15px;
        margin-bottom: 20px;
        background-color: #ffffff;
        border-radius: 8px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }
    h2, h3 {
        font-size: 18px;
    }

    .btn-success {
        font-size: 16px;
        padding: 10px 0;
    }

    table td, table th {
        font-size: 14px;
        padding: 5px;
    }
}
/* Media query for very small devices (mobile phones) */
@media (min-width: 576px) and (max-width : 600px) {
    /* Adjust headers for readability */
    h2, h3 {
        font-size: 16px;
        text-align: center;
    }
    .header{
        font-size: 1px;
        
    }

    /* Shrink container sizes */
    .container_pay, .container1 {
        width: 100%; 
        margin: 5px auto; 
        padding: 8px;
        box-shadow: none; /* Remove box shadow for cleaner look */
    }

    /* Adjust button sizes for small screens */
    .container1 .btn {
        font-size: 12px;
        padding: 10px;
    }

    /* Adjust tables for smaller viewports */
    table td, table th {
        font-size: 10px;
        padding: 5px;
    }

    /* Ensure tables scroll horizontally */
    .table-responsive {
        overflow-x: scroll;
    }

    /* Reduce padding and line height for wrap-text */
    .wrap-text {
        font-size: 12px;
        line-height: 1.2;
    }

    /* General body adjustments for better mobile experience */
    body {
        font-size: 12px;
    }

    /* Adjust button styling */
    .btn-success {
        font-size: 14px;
        padding: 8px 0;
    }

    /* Ensure Total row stands out even on small screens */
    table tr.bg td {
        font-size: 12px;
    }
}

}