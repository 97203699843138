.payment-details {
  font-family: "Poppins";
  background-color: #fff;
  .payment-screen {
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    padding: 0;
    .payment-header {
      box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
        0 2px 6px 2px rgba(60, 64, 67, 0.15);
      .head {
        font-weight: 700;
        display: flex;
        font-size: 20px;
        align-items: center;
        padding: 20px 15px;
        .back {
          color: #e26c28;
        }
        p {
          margin: 0;
          padding-left: 25px;
        }
      }
    }
    .payment-body {
      padding: 15px;
      .header {
        font-size: 19px;
        font-weight: 600;
        padding-top: 12px;
      }
      .payment-options {
        .list-group {
          .list-group-item {
            margin: 15px 0px;
            padding: 15px;
            box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
              0 2px 6px 2px rgba(60, 64, 67, 0.15);
            .list-label {
              width: 100%;
              .checkoutPayUI {
                display: flex;
                justify-content: space-between;
                .checkoutPayUIchild {
                  display: flex;
                  align-items: center;

                  img {
                    width: 40px;
                    height: 35px;
                    margin-right: 12px;
                  }
                  h5 {
                    margin: 0;
                  }
                }
              }
              .radio-button {
                position: absolute;
                right: 15px;
                top: 25px;
                left: auto;
                bottom: auto;
                background-color: #ededed;
                display: flex;
                justify-content: center;
                align-items: center;
                border: none;
                outline: none;
                border-radius: 50%;
                width: 22px;
                height: 22px;
                padding: 3px;
                svg {
                  fill: #173303;
                  color: #173303;
                  font-size: 25px;
                  -webkit-transition: all 0.3s ease;
                  -moz-transition: all 0.3s ease;
                  -o-transition: all 0.3s ease;
                  -ms-transition: all 0.3s ease;
                  transition: all 0.3s ease;
                }
                &.active {
                  svg {
                    transform: rotate(180deg);
                  }
                }
              }
            }
            .manual-card-payment {
              padding: 10px;
              .card-details {
                padding: 5px 0;
                .title {
                  font-size: 17px;
                  font-weight: 600;
                  padding: 10px 0;
                }
                input {
                  width: 100%;
                  height: 50px;
                  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
                    rgba(0, 0, 0, 0.23) 0px 3px 6px;
                  border: none;
                  border-radius: 10px;
                  outline: none;
                  padding: 10px;
                }
              }
              .card-details-main {
                padding: 5px 0;
                .expires {
                  .exp {
                    font-size: 17px;
                    font-weight: 600;
                    padding: 10px 0;
                  }
                  input {
                    width: 100%;
                    height: 50px;
                    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
                      rgba(0, 0, 0, 0.23) 0px 3px 6px;
                    border: none;
                    border-radius: 10px;
                    outline: none;
                    padding: 10px;
                  }
                }
                .Security {
                  .title {
                    font-size: 17px;
                    font-weight: 600;
                    padding: 10px 0px;
                  }
                  input {
                    width: 100%;
                    height: 50px;
                    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
                      rgba(0, 0, 0, 0.23) 0px 3px 6px;
                    border: none;
                    border-radius: 10px;
                    outline: none;
                    padding: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .payment-button {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      .payment {
        display: flex;
        justify-content: center;
        background: white;
        button {
          background-color: #9fe870;
          color: #173303;
          height: 55px;
          width: 100%;
          border: none;
          font-size: 20px;
          font-weight: 600;
          border-radius: 30px;
          display: flex;
          gap: 10px;
          align-items: center;
          justify-content: center;
          margin: 10px 15px;
        }
      }
    }
  }
}
