.main-view {
  background-color: #f0f0f0;
  font-family: "Poppins";
  margin: 0;
  padding: 0;
  height: 100%;
  .pad-imp {
    padding: 0px;
  }
  .payment-screen {
    height: calc(100vh - 75px);
    overflow: hidden;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .header {
      padding: 10px;
      .colse-option {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        .close {
          svg {
            width: 30px;
            height: 25px;
            object-fit: contain;
            visibility: hidden;
          }
        }
        .title {
          font-size: 20px;
          font-weight: 600;
          color: #000;
        }
        .options {
          svg {
            width: 17px;
            height: 25px;
            object-fit: contain;
          }
        }
      }
    }
    .details-amount-notes {
      font-family: "Poppins";
      .cust-details {
        .title {
          font-size: 20px;
          font-weight: 600;
          color: #333;
          text-align: center;
        }
        .amount {
          font-size: 60px;
          font-weight: 700;
          color: #000;
          text-align: center;
        }
        .cust-name {
          font-size: 15px;
          font-weight: 600;
          color: #000;
          text-align: center;
        }
        .input-notes {
          text-align: center;
          margin-top: 15px;
          input {
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            width: 25%;
            border-radius: 5px;
            outline: none;
            border: none;
            height: 40px;
            background-color: #f0f0f0;
          }
        }
      }
    }
    .proceed-btn {
      font-family: "Poppins";
      display: flex;
      justify-content: center;
      margin-top: 30px;
      .proceed-icon {
        background-color: #9fe870;
        border: none;
        height: 40px;
        width: 98%;
        border-radius: 50px;

        .cnt-btn {
          color: #173303;
          font-weight: 600;
          font-size: 15px;
          font-family: "Poppins";
        }
      }
    }
  }
}

.payment-methods {
  padding: 10px;
  .headings {
    font-size: 20px;
    font-weight: 700;
    padding: 25px 0px;
  }
  .types {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;

    .card-pay {
      display: flex;
      gap: 10px;
      width: 30%;
      border: 0.5px solid;
      border-radius: 10px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: #173303;
      font-weight: 600;
      cursor: pointer;
      img {
        width: 25px;
      }
    }

    .apple-pay {
      display: flex;
      gap: 10px;
      width: 30%;
      border: 0.5px solid;
      border-radius: 10px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: #173303;
      font-weight: 600;
      cursor: pointer;
      img {
        width: 25px;
      }
    }
    .google-pay {
      display: flex;
      gap: 10px;
      width: 30%;
      border: 0.5px solid;
      border-radius: 10px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: #173303;
      font-weight: 600;
      cursor: pointer;
      img {
        width: 25px;
      }
    }
  }
}
.payment-btn {
  padding: 10px;
  button {
    width: 100%;
    height: 60px;
    display: flex;
    margin: 0px auto;
    border: none;
    background-color: #173303;
    color: #9fe870;
    font-weight: 600;
    gap: 10px;
    font-size: 17px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }
}

// keypad
.Mobile-key-pad {
  font-family: "Poppins";
  height: auto;
  margin-bottom: 4px;
  .number-modify {
    td {
      text-align: center;
      border: none;
      box-shadow: none;
      width: calc(100% / 3);
      button {
        height: 45px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        font-size: 17px;
        font-weight: 700;
        border-radius: 5px;
        color: #000;
        background-color: #fff;
        svg {
          font-size: 17px;
        }
      }
      .center {
        display: flex;
        justify-content: center;
        margin: 5px 5px;
      }
    }
  }
}
.keypad {
  padding: 5px;
  background: #d1d3da;
}

/// tooltip button
.help-feedback {
  font-family: "Poppins";
  .tooltip-btn {
    background-color: transparent;
    border: none;
    outline: none;
    color: #000;
  }
}
.popover-body {
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 600;
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .popover-body {
    padding: 7px;
  }
  .main-view .payment-screen .header .colse-option .title {
    font-size: 27px;
  }
  .main-view .payment-screen .details-amount-notes .cust-details .title {
    font-size: 19px;
  }
  .main-view .payment-screen .details-amount-notes .cust-details .amount {
    font-size: 57px;
  }
  .main-view .payment-screen .details-amount-notes .cust-details .cust-name {
    font-size: 14px;
  }
  .main-view
    .payment-screen
    .details-amount-notes
    .cust-details
    .input-notes
    input {
    font-size: 15px;
  }
  .main-view .payment-screen .header .colse-option .options svg {
    height: 20px;
  }
  .main-view .payment-screen .header .colse-option .close svg {
    height: 20px;
  }
}
@media screen and (min-width: 421px) and (max-width: 575px) {
  .main-view {
    background-color: #fff;
  }

  .popover-body {
    padding: 7px;
  }
  .main-view .payment-screen .header .colse-option .title {
    font-size: 25px;
  }
  .main-view .payment-screen .details-amount-notes .cust-details .title {
    font-size: 18px;
  }
  .main-view .payment-screen .details-amount-notes .cust-details .amount {
    font-size: 55px;
  }
  .main-view .payment-screen .details-amount-notes .cust-details .cust-name {
    font-size: 14px;
  }
  .main-view
    .payment-screen
    .details-amount-notes
    .cust-details
    .input-notes
    input {
    font-size: 14px;
  }
  .main-view .payment-screen .header .colse-option .options svg {
    height: 20px;
  }
  .main-view .payment-screen .header .colse-option .close svg {
    height: 20px;
  }
  .main-view .payment-screen .proceed-btn .proceed-icon svg {
    font-size: 23px;
  }
}
@media screen and (min-width: 320px) and (max-width: 420px) {
  .table > :not(caption) > * > * {
    padding: 0px;
  }

  .main-view {
    background-color: #fff;
  }

  .popover-body {
    padding: 5px;
    font-size: 12px;
  }
  .main-view .payment-screen .header .colse-option .title {
    font-size: 20px;
  }
  .main-view .payment-screen .details-amount-notes .cust-details .title {
    font-size: 24px;
  }
  .main-view .payment-screen .details-amount-notes .cust-details .amount {
    font-size: 45px;
  }
  .main-view .payment-screen .details-amount-notes .cust-details .cust-name {
    font-size: 14px;
  }
  .main-view
    .payment-screen
    .details-amount-notes
    .cust-details
    .input-notes
    input {
    font-size: 13px;
    width: 30%;
  }
  .main-view .payment-screen .header .colse-option .options svg {
    height: 18px;
  }
  .main-view .payment-screen .header .colse-option .close svg {
    height: 18px;
  }
  .main-view .payment-screen .proceed-btn .proceed-icon svg {
    font-size: 23px;
  }
}

// pay btn
.click-pay {
  padding: 5px;

  .pay {
    width: 100%;
    height: 50px;
    font-size: 20px;
    font-weight: 600;
    color: #173303;
    background-color: #9fe870;
    border-radius: 10px;
    font-family: "Poppins";
    border: none;
  }
}
.table {
  --bs-table-bg: #d1d3da;
}
.custom-payment-button {
  padding: 3px 13px !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  color: #fff !important;
  border: none !important; 
  border-radius:70px !important;
}

body {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

.input-notes {
  position: relative;
}

.edit-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  color: pink;
  cursor: pointer;
}

.amount {
  cursor: pointer;
  color: gray;
}

.amount.active {
  color: black;
}


.amount-container {
  position: relative;
}

.amount-line {
  position: absolute;
  border: none;
  border-top: 1px solid rgb(70, 69, 69); 
  width: calc(26% - 20px);
  left: 50%; 
  bottom: 0;
  transform: translateX(-50%);
}







