.failed-details {
  font-family: "Poppins";
  background-color: #fff;
  .success-screen {
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .gif {
      display: flex;
      margin: 0px auto;
      flex-direction: column;
      align-items: center;
    }
  }
  .paid-info {
    text-align: center;
    .amount {
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 50px;
      color: #000;
    }
    .cust-name {
      font-size: 25px;
      font-weight: 600;
      color: #000;
    }
    .date {
      font-size: 25px;
      font-weight: 600;
      color: #000;
    }
  }
  .try {
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;

    button {
      background-color: #9fe870;
      color: #173303;
      height: 50px;
      width: 50%;
      border: none;
      font-size: 17px;
      font-weight: 600;
      border-radius: 10px;
    }
  }
}
a {
  text-decoration: none;
}
