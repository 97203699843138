.success-details {
  font-family: "Poppins";
  background-color: #fff;
  .success-screen {
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .gif {
      display: flex;
      margin: 0px auto;
      flex-direction: column;
    }
  }
  .paid-info {
    text-align: center;
    .amount {
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 15px;
      color: #000;
    }
    .cust-name {
      font-size: 25px;
      font-weight: 600;
      color: #000;
    }
    .cust-date {
      font-size: 17px;
      font-weight: 600;
      color: #000;
    }
    .date {
      font-size: 14px;
      font-weight: 500;
      color: #8c8c8c;
    }
  }
  .share-done {
    display: flex;
    width: 100%;
    padding: 10px;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    .share {
      width: 50%;
      display: flex;
      justify-content: center;
      button {
        background-color: #9fe870;
        color: #173303;
        height: 50px;
        width: 180px;
        border: none;
        font-size: 17px;
        font-weight: 600;
        border-radius: 30px;
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
      }
    }
    .done {
      width: 50%;
      display: flex;
      justify-content: center;
      button {
        background-color: #9fe870;
        color: #173303;
        height: 50px;
        width: 180px;
        border: none;
        font-size: 17px;
        font-weight: 600;
        border-radius: 30px;
      }
    }
  }
}
